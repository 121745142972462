import type { SVGProps } from 'react';
import { memo } from 'react';
const SvgChevronDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg fill="none" height="24px" viewBox="0 0 25 24" width="24px" {...props}>
    <path
      clipRule="evenodd"
      d="M11.884 15.707a1 1 0 0 0 1.415 0l5-5a1 1 0 0 0-1.415-1.414l-4.293 4.293-4.293-4.293a1 1 0 1 0-1.414 1.414z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);
const Memo = memo(SvgChevronDownIcon);
export default Memo;
